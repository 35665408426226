<style src="./FormFirstAccess.scss" lang="scss" scoped></style>
<template>
  <section class="form-first-access">
    <InputText
      v-model="form.name"
      label="Nome Completo"
      class="input-100"
      isRequired
      :maxlength="100"
    />

    <InputText 
      v-model="form.birth"
      class="input-30"
      label="Data de Nascimento" 
      isRequired
      placeholder="DD/MM/YYYY"
      mask="##/##/####"
    />

    <div class="radio-sex input-auto">
      <span class="label">Sexo <span style="color: #FF0000;">*</span></span>
      <div class="radio-input">
        <label style="margin-right: 1rem;">
          <input type="radio" v-model="form.sex" value="m" />Masculino
        </label>
        <label><input type="radio" v-model="form.sex" value="f" />Feminino</label>
      </div>
    </div>

    <InputText 
      v-model="form.cpf"
      class="input-30"
      label="CPF" 
      isDisabled
      isRequired
      mask="###.###.###-##"
    />

    <InputText 
      v-model="form.mother"
      class="input-100"
      label="Nome da Mãe" 
      isRequired
    />

    <InputText 
      v-model="form.email"
      class="input-50"
      label="E-mail" 
      placeholder="seuemail@dominio.com"
      isRequired
    />

    <InputText 
      v-model="form.phone"
      class="input-50"
      label="Telefone" 
      isRequired
      placeholder="(DDD)99999-9999"
      mask="(##)#####-####"
    />

    <div class="line-row"/>

    <InputText 
      v-model="form.cep"
      class="input-30"
      label="CEP"
      mask="#####-###"
      placeholder="00000-000"
      isRequired
      @blur="onBlurZipCode"
    />

    <InputText 
      v-model="form.street"
      class="input-30"
      label="Logradouro"          
      isRequired
    />

    <InputText
      v-model="form.number"
      placeholder="000"
      class="input-30"
      label="Número"
      isRequired
    />
    
    <InputText
      v-model="form.additional"
      class="input-30"
      label="Complemento"
    />

    <InputText 
      v-model="form.neighborhood"
      class="input-30"
      label="Bairro"
      isRequired
      />
      <!-- :isDisabled="disableAddressFields" -->

    <InputText 
      v-model="form.city"
      class="input-30"
      label="Município"
      isRequired
      :only-letters="true"
      :isDisabled="disableAddressFields"
    />

    <SelectCountry
      class="flexOne input-30 -not-grow"
      label="Estado"
      placeholder="Selecione"
      :list="listCountry"
      :value="form.usu_estado"
      :isDisabled="disableAddressFields"
      @changeInfo="changeSelectCountry"
      required
    />

    <div class="line-row"/>

    <InputText 
      v-model="form.newPsw"
      class="input-50"
      label="Nova Senha" 
      isRequired 
      type="password"
    />
    <InputText 
      v-model="form.confirmPsw" 
      class="input-50"
      label="Confirmar Nova Senha" 
      isRequired
      type="password"
    />
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import { ServicoHttp } from '@/axios/servico-http';
import InputText from '@/components/input/text/InputText';
import { ServicoAll } from '@/axios/servico-all';
import SelectCountry from './SelectCountry';

export default {
  name: 'FormFirstAccess',
  components: { InputText, SelectCountry },

  props: {
    newCpf: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      servicoHttp: null,
      ServicoAll: null,
      listCountry: [],
      patient: null,
      disableAddressFields: false,
      form: {
        name: '',
        birth: '',
        sex: null,
        cpf: '',
        mother: '',
        email: '',
        phone: '',
        cep: null,
        street: null,
        number: null,
        neighborhood: null,
        city: null,
        additional: '',
        newPsw: null,
        usu_estado: null,
        confirmPsw: null
      }
    };
  },

  watch: {
    'form.cep' (pZipcode) {
      if (pZipcode.length === 9) {
        this.getAddressByZipCode(this.form.cep)
      }
    }
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.getStates = new ServicoAll(`/api/pep/address/get-states`, this.servicoHttp);
    this.loadAddress();
    const storageUser = await this.$store.dispatch('getLocalStoragePatient');
    const patientFromHash = await this.$store.dispatch('getPatientToVideoUrl');
    this.patient = patientFromHash || storageUser;
    if (this.patient) {
      this.form = {
        name: this.patient.pac_nome,
        birth: moment(this.patient.usu_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        sex: this.patient.usu_genero,
        cpf: this.patient.usu_cpf,
        mother: this.patient.usu_nome_mae,
        email: this.patient.usu_email,
        phone: this.patient.usu_telefone,
        cep: this.patient.usu_cep,
        street: this.patient.usu_logradouro,
        number: this.patient.usu_endereco_numero,
        additional: this.patient.usu_complemento,
        neighborhood: this.patient.usu_bairro,
        usu_estado: this.patient.usu_estado,
        city: this.patient.usu_municipio
      };

      if (this.patient.usu_cep) {
        await this.getAddressByZipCode(this.patient.usu_cep, { clearOnError: false })
      }
    } else if (this.newCpf) {
      this.form.cpf = this.newCpf
    }
  },

  computed: {},

  methods: {
    async loadAddress() {
      try {
        const { data: responseUF } = await this.getStates.get();
        this.listCountry = responseUF?.map(item => ({
          value: item.est_sigla,
          label: `${item.est_nome} - ${item.est_sigla}`
        }));

        if (this.addressMetadata && this.addressMetadata.usu_cep) {
          this.setFormData(this.addressMetadata);
        }
      } catch (error) {
        throw error;
      }
    },
    getPayload() {
      if (this.form.name.trim().length === 0) {
        return {
          status: false,
          msg: 'Nome do paciente é obrigatório.'
        };
      }

      var regEx = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~0-9]/;
      if (regEx.test(this.form.name)) {
        return {
          status: false,
          msg: 'O Nome não pode conter números e nem letras especiais.'
        };
      }

      const split = this.form.name.split(' ');
      if (split.length < 2) {
        return {
          status: false,
          msg: 'O Nome deve conter nome e sobrenome.'
        };
      }
      if (split[0].length < 2) {
        return {
          status: false,
          msg: 'O Nome deve conter mais de 2 letras.'
        };
      }
      if (split[1].length < 2) {
        return {
          status: false,
          msg: 'O Sobrenome deve conter mais de 2 letras.'
        };
      }

      if (!this.form.birth || this.form.birth.length < 1) {
        return {
          status: false,
          msg: 'O campo Data de Nascimento é obrigatório'
        };
      }
      const birthMom = moment(this.form.birth, 'DD/MM/YYYY');
      if (!birthMom.isValid()) {
        return {
          status: false,
          msg: 'O campo Data de Nascimento está inválido'
        };
      }
      if (birthMom.isAfter(moment())) {
        return {
          status: false,
          msg: 'O campo Data de Nascimento deve ser antes da Data atual'
        };
      }

      if (this.form.sex === null) {
        return {
          status: false,
          msg: 'O campo Sexo é obrigatório'
        };
      }

      if (!this.form.cpf) {
        return {
          status: false,
          msg: 'O campo CPF é obrigatório'
        };
      }
      if (this.form.cpf.length < 14) {
        return {
          status: false,
          msg: 'O campo CPF deve ter no mínimo 11 números'
        };
      }

      if (!this.form.mother) {
        return {
          status: false,
          msg: 'O campo Nome da Mãe é obrigatório'
        };
      }
      if (this.form.mother.length < 3) {
        return {
          status: false,
          msg: 'O campo Nome da Mãe deve ter no mínimo 3 letras'
        };
      }

      if (!this.form.email) {
        return {
          status: false,
          msg: 'O campo E-mail é obrigatório'
        };
      }
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailValid = regexEmail.test(this.form.email);
      if (!emailValid) {
        return {
          status: false,
          msg: 'O campo E-mail está inválido'
        };
      }

      if (!this.form.phone) {
        return {
          status: false,
          msg: 'O campo Telefone é obrigatório'
        };
      }
      if (this.form.phone.length < 14) {
        return {
          status: false,
          msg: 'O campo Telefone está inválido'
        };
      }

      //address
      const addressRequiredValidation = [
        {
          cep: 'O CEP é obrigatório.'
        },
        {
          street: 'O Logradouro é obrigatório.'
        },
        {
          number: 'O Número é obrigatório.'
        },
        // {
        //   neighborhood: 'O Bairro é obrigatório.'
        // },
        {
          usu_estado: 'O Estado é obrigatório.'
        },
        {
          city: 'O Município é obrigatório.'
        }
      ];
      for (const item of addressRequiredValidation) {
        const [key, value] = Object.entries(item)[0];
        if (!this.form[key] || this.form[key].trim().length === 0) {
          return {
            status: false,
            msg: value
          };
        }
      }
      //
      if (!this.form.newPsw) {
        return {
          status: false,
          msg: 'O campo Nova Senha é obrigatório'
        };
      }
      if (!this.form.confirmPsw) {
        return {
          status: false,
          msg: 'O campo Confirmar Nova Senha é obrigatório'
        };
      }
      if (!this.isValidPassword(this.form.newPsw)) {
        return {
          status: false,
          msg: 'A senha deve conter no mínimo oito caracteres e incluir pelo menos um caractere maiúsculo, um caractere minúsculo e um caractere especial.'
        };
      }
      if (this.form.confirmPsw !== this.form.newPsw) {
        return {
          status: false,
          msg: 'O campo Confirmar Nova Senha está diferente do campo Nova Senha'
        };
      }

      return {
        status: true,
        form: this.form
      };
    },


    changeSelectCountry(val) {
      this.form.usu_estado = val;
      if (val.value) {
        this.form.usu_estado = val.value;
      }
    },

    // * VALIDACAO
    isValidPassword(password) {
      // Pelo menos um caractere maiúsculo
      const upperCaseRegex = /[A-Z]/;
      // Pelo menos um caractere minúsculo
      const lowerCaseRegex = /[a-z]/;
      // Pelo menos um caractere especial
      const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      // Mínimo de oito caracteres
      const lengthRegex = /.{8,}/;

      return (
        upperCaseRegex.test(password) &&
        lowerCaseRegex.test(password) &&
        specialCharacterRegex.test(password) &&
        lengthRegex.test(password)
      );
    },

    onBlurZipCode(e) {
      this.getAddressByZipCode(this.form.cep)
    },

    async getAddressByZipCode(zipcode, options = { clearOnError: true }) {
      try {
        const url = `https://viacep.com.br/ws/${zipcode}/json/`;

        const { data } = await axios.get(url);

        if (data.erro === 'true') {
          throw new Error()
        }

        if (data) {
          this.disableAddressFields = true
          this.form = Object.assign(this.form, {
            cep: data.cep,
            neighborhood: data.bairro,
            city: data.localidade,
            usu_estado: data.uf,
            street: data.logradouro
          })
        }
      } catch (error) {
        console.error('CEP ERROR: ', error)
        this.disableAddressFields = false
        if (options.clearOnError) {
          const hasZipCode = zipcode.length > 0
          if (hasZipCode) {
            this.$toast.warning(
              "CEP informado incorreto ou não encontrado. Para prosseguir, informe os campos manualmente."
            );
          }
          this.form = Object.assign(this.form, {
            neighborhood: '',
            city: '',
            usu_estado: '',
            street: ''
          })
        }
      }
    }
  }
};
</script>
