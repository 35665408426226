<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  async mounted () {
  }
}
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  font-size: 14px;
  cursor: default;
}
body {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.v-toast {
  max-width: 700px !important;
}

.v-toast.v-toast--bottom {
    right: 0;
    left: unset;
}
</style>
