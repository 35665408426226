<style lang="scss" src="./InputText.scss" scoped></style>
<template>
  <div class="input-text">
    <div class="label">
      {{ label }}
      <span v-if="isRequired" style="color: #ff0000">*</span>
    </div>

    <div class="input-wrapper">
      <input
        v-if="mask"
        class="input"
        :class="{ 'input-error': inputError }"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :type="computedType"
        @blur="blur"
        @focus="focus"
        :maxlength="maxlength"
        v-mask="mask"
        @keydown="handleKeydown"
        v-model="input"
      />

      <input
        v-else
        v-model="input"
        :class="{ 'input-error': inputError }"
        :disabled="isDisabled"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :type="computedType"
        class="input"
        @keydown="handleKeydown"
        @blur="blur"
        @focus="focus"
      />

      <span
        v-if="type === 'password'"
        class="toggle-password"
        @click="togglePasswordVisibility"
      >
        <i :class="showPassword ? 'far fa-eye-slash' : 'far fa-eye'"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'ProviderInputText',
  props: {
    value: [String, Number],
    label: String,
    placeholder: {
      type: String,
      default: 'Digite aqui'
    },
    type: {
      type: String
    },
    mask: undefined,
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    validate: null,
    maxlength: null,
    onlyLetters: false
  },

  directives: { mask },

  data() {
    return {
      input: '',
      inputError: false,
      showPassword: false
    };
  },

  computed: {
    computedType() {
      return this.type === 'password' && this.showPassword ? 'text' : this.type;
    }
  },

  watch: {
    input(eventValue) {
      this.$emit('input', eventValue);
    },
    value() {
      this.input = this.value;
    }
  },

  mounted() {
    this.input = this.value ? this.value : '';
  },

  methods: {
    handleKeydown(event) {
      if (!this.onlyLetters) {
        return true;
      }
      const isLetter = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/i.test(event.key);
      if (!isLetter) {
        return event.preventDefault();
      }
    },
    blur(e) {
      this.checkValidate();
      this.$emit('blur', e);
    },
    focus(e) {
      this.$emit('focus', e); // Corrigido para emitir o evento de foco
    },
    checkValidate() {
      this.inputError = false;
      if (typeof this.validate === 'function') {
        let valid = this.validate(this.input);
        if (valid !== true) {
          this.inputError = true;
        }
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>

<style scoped>
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-container input {
  padding-right: 30px;
  width: 100%;
}

.toggle-visibility {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
</style>
