<style src="./PatientSchedule.scss" lang="scss" scoped></style>
<template>
  <section class="patient-schedule">
    <Loading v-if="isLoading" />

    <PatientHeader />

    <section class="title">
      <span
        :style="{ 'text-decoration': 'underline', cursor: 'pointer' }"
        @click="onClickBack"
      >
        Início
      </span>
      <IconArrow />
      <span>Solicitar Agendamento</span>
    </section>

    <section class="content">
      <div class="form">
        <SelectInput
          v-model="form.type"
          class="mr"
          required
          label="Solicitar agendamento para:"
          :disabled="disabledSpecialization"
          :options="optFunType"
          :preselect-first="false"
          @input="selectedSpecialization"
        />
        <SelectInput
          v-model="form.agp_id_funcionarios"
          class="mr"
          required
          label="Profissional"
          :disabled="disabledProfessionalSelect"
          :options="professionalOptions"
          :preselect-first="false"
        />
        <SelectInput
          v-model="form.agp_data"
          required
          class="mr"
          label="Data da consulta"
          :options="dateOptions"
          :preselect-first="false"
          :disabled="disabledDateSelect"
        />
        <SelectInput
          v-model="form.agp_horario_inicio"
          class="mr"
          required
          label="Horário"
          :options="hourOptions"
          :preselect-first="false"
          :disabled="disabledHourSelect"
        />
      </div>

      <div
        v-if="alertMessage.isOpen"
        class="msg"
        :data-type="alertMessage.type"
      >
        {{ alertMessage.message }}
      </div>
    </section>

    <div class="footer">
      <div>
        <PlButton label="" primary arrowLeft @click="onClickBack" />
      </div>
      <div class="footer-flex">
        <PlButton label="Cancelar" gray cross @click="onClickBack" />
        <PlButton
          label="Solicitar Agendamento"
          primary
          check
          :disabled="disableButton"
          @click="handleSubmit"
        />
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";
import MovementLogs from "@/helpers/movement_logs";

import Loading from "../components/loading/Loading";
import PatientHeader from "../components/header/PatientHeader";

import InputText from "@/components/input/text/InputText";
import PlButton from "@/components/button/Button";
import SelectInput from "@/components/select-input/SelectInput.vue";
import IconArrow from "@/assets/icons/IconArrow";

export default {
  name: "PatientSchedule",
  components: {
    IconArrow,
    InputText,
    PlButton,
    SelectInput,
    PatientHeader,
    Loading,
  },

  data() {
    return {
      isLoading: false,
      patient: null,
      inputCpf: null,

      msg: "",
      form: {
        type: null,
        agp_id_especialidades: null,
        agp_id_funcionarios: null,
        agp_data: null,
        agp_horario_inicio: null,
      },

      optFunType: [{ value: null, name: "Selecione" }],
      professionalOptions: [],
      dateOptions: [],
      hourOptions: [],
      alertMessage: {
        isOpen: false,
        type: null,
        message: "",
      },
      disableButton: false,
      disabledSpecialization: false,
      appointmentSchedulingContract: null,
      currentGiftCard: null,
      totalAppointmentQuotes: 0,
    };
  },

  computed: {
    disabledProfessionalSelect() {
      return this.form.type === null || this.professionalOptions.length === 0;
    },
    disabledDateSelect() {
      return (
        this.form.agp_id_funcionarios === null || this.dateOptions.length === 0
      );
    },
    disabledHourSelect() {
      return this.form.agp_data === null || this.hourOptions.length === 0;
    },
    hasScheduleContract() {},
  },

  watch: {
    "form.type"(specialization) {
      this.form.agp_data = null;
      this.form.agp_horario_inicio = null;
      this.form.agp_id_funcionarios = null;

      this.professionalOptions = [];
      this.dateOptions = [];
      this.hourOptions = [];

      if (specialization?.value) {
        this.getProfessionalBySpecialization(specialization);
      }
    },
    "form.agp_id_funcionarios"(value) {
      this.form.agp_data = null;
      this.form.agp_horario_inicio = null;

      this.dateOptions = [];
      this.hourOptions = [];

      if (value) {
        this.getProfessionalCalendarDays(value);
      }
    },
    "form.agp_data"(value) {
      this.form.agp_horario_inicio = null;
      this.hourOptions = [];

      if (value) {
        this.getScheduleHour(value);
      }
    },
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.serviceProfessional = new ServicoAll(
      "/api/pep/professional/get-professional-by-specialization",
      this.servicoHttp
    );
    this.sScheduleGetHour = new ServicoAll(
      "/api/pep/schedule/get-hour-fun",
      this.servicoHttp
    );
    this.sScheduleHandle = new ServicoAll(
      "/api/pep/schedule/handle",
      this.servicoHttp
    );
    this.sPepPacLinks = new ServicoAll(
      "/api/pep/schedule/links",
      this.servicoHttp
    );

    this.sPepForward = new ServicoAll(
      "/api/pep/circle/forward",
      this.servicoHttp
    );

    this.patient = await this.$store.dispatch("getLocalStoragePatient");
    this.appointmentSchedulingContract =
      this.$route.params.appointmentSchedulingContract;

    this.sPepCheckCurrentGiftCard = new ServicoAll(
      "/api/pep/gift-card/check-current-gift-card",
      this.servicoHttp
    );
    this.sPepGetAppointmentQuotasByPacId = new ServicoAll(
      "/api/pep/gift-card/get-appointment-quotas-by-pac-id-and-type-of-service",
      this.servicoHttp
    );

    this.sGetScaleHourByDate = new ServicoAll('/api/pep/escala/get-scale-hour-available', this.servicoHttp)

    // await this.checkGiftCard()

    await this.loadSpecialtiesWithQuota();

    // ! Firebase LOG
    const objLog = { ...this.patient };
    const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
    MovementLogs.saveLog(1300, timeAction, objLog);
  },

  methods: {
    selectedSpecialization(specialization) {
      this.form.agp_id_especialidades =
        specialization && specialization.value ? specialization.value : null;
    },
    onClickBack() {
      // ! Firebase LOG
      const objLog = { ...this.patient };
      const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
      MovementLogs.saveLog(1302, timeAction, objLog);

      this.$router.push({ name: "pacienteHome" });
    },
    showAlertMessage(type, message) {
      if (!["error", "success"].includes(type)) {
        console.error(
          `Alert Message only accept ['error', 'success'] as type!`
        );
      }

      this.alertMessage = {
        isOpen: true,
        type,
        message,
      };

      setTimeout(() => {
        this.alertMessage = {
          isOpen: false,
          type: null,
          message: "",
        };
      }, 3000);
    },

    async loadSpecialtiesWithQuota() {
      const specialtiesWithQuotaService = new ServicoAll(
        "/api/pep/contracts/specialties-with-quota",
        this.servicoHttp
      );
      const specialtiesWithQuota = await specialtiesWithQuotaService.get({
        pac_id: this.patient.pac_id,
      });

      const filteredSpecialties = specialtiesWithQuota.filter(specialty => specialty.value !== null);
      this.optFunType = [
        { value: null, name: 'Selecione' },
        ...filteredSpecialties
      ]

      if (this.optFunType.length === 1 && this.optFunType[0].value === null) {
        this.disabledSpecialization = true
        this.$toast.success("Você atingiu o limite de solicitação de agendamento.")
      }

      this.isLoading = false;
    },

    async checkGiftCard() {
      const resCheckCurrentGiftCard = await this.sPepCheckCurrentGiftCard.post({
        idPac: this.patient.pac_id,
      });

      if (resCheckCurrentGiftCard.data.data.length > 0) {
        this.currentGiftCard = resCheckCurrentGiftCard.data.data[0].gtc_codigo;

        const { data: retGetAppointmentQuotasByPacId } =
          await this.sPepGetAppointmentQuotasByPacId.post({
            idPac: this.patient.pac_id,
            idCbo: this.form.type.value,
          });

        this.totalAppointmentQuotes = 0;
        if (retGetAppointmentQuotasByPacId.status) {
          if (retGetAppointmentQuotasByPacId.data.length > 0) {
            for (const item of retGetAppointmentQuotasByPacId.data) {
              this.totalAppointmentQuotes += Number(item.quantidade_total);
            }
          }
        }
      }
    },

    async getProfessionalBySpecialization() {
      const owner = this.getOwner
      const isMedicar = owner === 'medicar'

      const medicarExtraMessage = isMedicar ? ' Nossas agendas são abertas todo dia 20 e atualizadas nos dias 5 e 15 de cada mês.' : ''
      
      try {
        this.isLoading = true
        const professionals = await this.serviceProfessional.get({
          esp_id: this.form.agp_id_especialidades,
          withSchedule: true,
        });

        if (!professionals.status || professionals?.data?.length === 0) {
          return this.$toast.error(
            "Especialidade com agendas esgotadas, orientamos aguardar abertura de novas datas." + medicarExtraMessage
          );
        }

        this.professionalOptions = professionals.data.map((item) => ({
          value: item.fun_id,
          name: item.fun_nome,
        }));

        await this.checkGiftCard();
      } catch (error) {
        console.error(error);
        return this.$toast.info(
          "Especialidade com agendas esgotadas, orientamos aguardar abertura de novas datas." + medicarExtraMessage
        );
      } finally {
        this.isLoading = false
      }
    },

    async getProfessionalCalendarDays(professional) {
      // TODO - passar aqui a especialidade
      try {
        this.isLoading = true
        const { data: response } = await this.servicoHttp.post({
          url: "api/pep/schedule/obter-dias-horas-por-profissional",
          body: {
            professionalId: professional.value,
            patientId: this.patient.pac_id,
            specialityId: this.form.agp_id_especialidades
          },
        });

        if (response.status === false || response.list.length === 0) {
          const owner = this.getOwner
          const isMedicar = owner === 'medicar'

          const medicarExtraMessage = isMedicar ? ' Nossas agendas são abertas todo dia 20 e atualizadas nos dias 5 e 15 de cada mês.' : ''

          return this.$toast.error(
            "Profissional com agenda esgotada, orientamos aguardar abertura de novas datas."+ medicarExtraMessage
          );
        }

        this.dateOptions = response.list
          .map((item) => {
            const date = moment(item.date, "YYYY-MM-DD").format("DD/MM/YYYY");
            return {
              value: date,
              name: date,
              item: item,
            };
          })
          .sort((a, b) => {
            const dateA = new Date(a.name.split("/").reverse().join("/"));
            const dateB = new Date(b.name.split("/").reverse().join("/"));
            return dateA - dateB;
          });
      } catch (error) {
        console.error(error);
        return this.$toast.error(
          "Falha ao buscar agenda do profissional, tente novamente mais tarde!"
        );
      } finally {
        this.isLoading = false
      }
    },

    async getScheduleHour(value) {
      try {
        this.isLoading = true
        const payloadgetHours = {
          date: moment(this.form.agp_data.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          professionalId:  this.form.agp_id_funcionarios.value
        }
        const objListHours = await this.sGetScaleHourByDate.get(payloadgetHours)
        const listHours = objListHours.list

        this.hourOptions = value.item.hours.map(item => ({
          agfId: item.agf_id,
          value: item.agf_horario_inicio,
          name: item.agf_horario_inicio
        }))

        const commonHours = this.hourOptions.filter(item1 =>
          listHours.some(item2 => item2.agf_horario_inicio === item1.value)
        )
        this.hourOptions = commonHours
      } catch (error) {
        console.error(error);
        return this.$toast.error(
          "Falha ao buscar horarios, tente novamente mais tarde!"
        );
      } finally {
        this.isLoading = false
      }
    },

    async handleSubmit() {
      try {
        this.disableButton = true;

        const requirements = Object.values(this.form).some((item) => {
          return item === null || item.value === null;
        });

        if (requirements) {
          return this.$toast.warning("Verifique os campos");
        }

        const currentDateTime = moment();
        const formDateTime = moment(
          `${this.form.agp_data.value} ${this.form.agp_horario_inicio.value}`,
          "DD/MM/YYYY HH:mm"
        );
        const minutesDifference = formDateTime.diff(currentDateTime, "minutes");

        if (formDateTime.isBefore(currentDateTime)) {
          return this.$toast.info(
            "Não é possível realizar agendamento retroativo"
          );
        }

        if (minutesDifference > 0 && minutesDifference < 15) {
          return this.$toast.info(
            "O horário ultrapassou o tempo mínimo de antecedência de 15 minutos"
          );
        }

        const retHistoric = await this.getHistoric();
        if (!retHistoric) {
          return;
        }

        // console.log(this.data, 'data');
        // console.log(this.totalAppointmentQuotes, 'this.totalAppointmentQuotes');

        const info = {
          agp_id_especialidades: this.form.agp_id_especialidades,
          funId: this.form.agp_id_funcionarios.value,
          nextFunId: this.form.agp_id_funcionarios.value,
          pacId: this.patient.pac_id,
          type: 3, // agendamento
          date: this.form.agp_data.value,
          hour: this.form.agp_horario_inicio.value,
          professionalScheduleId: this.form.agp_horario_inicio.agfId,
          agp_agendado_giftcard: this.totalAppointmentQuotes > 0,
        };
        // console.log(info);
        const ret = await this.sPepForward.post(info);
        if (ret.data.status === false) {
          return this.$toast.error(ret.data.msg, {
            duration: 2000,
          });
        }

        // ! Firebase LOG
        const objLog = { ...this.patient, ...ret.data, ...info };
        const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
        MovementLogs.saveLog(1301, timeAction, objLog);

        this.form = {
          type: null,
          agp_id_funcionarios: null,
          agp_data: null,
          agp_horario_inicio: null,
          agp_id_especialidades: null,
        };

        if (this.currentGiftCard === null) {
          await this.loadSpecialtiesWithQuota();
        }

        this.totalAppointmentQuotes = 0;

        this.$toast.success("Agendamento realizado com sucesso!");
        return this.$router.push({ name: "pacienteLinks" });
      } catch (error) {
        console.error(error);
        return this.$toast.error(
          "Falha ao tentar realizar agendamento, tente novamente mais tarde!"
        );
      } finally {
        setTimeout(() => {
          this.disableButton = false;
        }, 1600);
      }
    },

    async getHistoric() {
      const { data: response } = await this.sPepPacLinks.post({
        pacId: this.patient.pac_id,
      });

      if (!response.status) {
        this.$toast.error(
          "Falha ao obter agendamento já realizado, tente novamente mais tarde!"
        );
        return false;
      }

      let isValid = true;
      console.log(response.data);
      response.data.map((item) => {
        if (this.form.agp_data.value === item.agp_data) {
          if (item.agp_cancelado === 1) {
            return true;
          } else if (
            this.form.agp_horario_inicio.value === item.agp_horario_inicio
          ) {
            this.$toast.error(
              "Já existe um atendimento agendado para a data e horário selecionados"
            );
            isValid = false;
          }
        }
      });
      return isValid;
    },
  },
};
</script>
