<style src="./PasswordReset.scss" lang="scss" scoped>
</style>

<template>
  <section class="password-reset">
    <main class="password-reset__main-container">
    <section class="password-reset__main">
      <img class="password-reset__header__logo" :src="appLogo" />
      <h1>Defina uma nova <strong>Senha</strong></h1>
      <TexInput type="password" label="Nova senha" v-model="newPassword" />
      <span>A senha deve possuir:</span>
      <PasswordCheckIndicator ref="PasswordCheckIndicator" :newPassword="newPassword" />
      <TexInput type="password" label="Repita a nova senha" v-model="passwordConfirm" />
      <Button
        class="password-reset__main__confirm"
        :disabled="disableConfirm"
        label="Confirmar"
        primary
        @click="handleSubmit"
      />
    </section>
    <aside class="password-reset__aside">
      <div class="password-reset__aside-gradient"></div>
    </aside>
    </main>
  </section>
</template>

<script>

import TextInputPassword from '@/components/inputs/text-input-password/TextInputPassword.vue';
import TexInput from '@/components/input/text/InputText.vue';
import Button from '@/components/button/Button.vue';
import PasswordCheckIndicator
  from '@/views/paciente/password-reset/components/password-check-indicator/PasswordCheckIndicator.vue';
import { ServicoHttp } from '@/axios/servico-http';
import { ServicoAll } from '@/axios/servico-all';

export default {
  name: 'PasswordReset',
  components: { PasswordCheckIndicator, Button, TexInput, TextInputPassword },
  data() {
    return {
      newPassword: '',
      passwordConfirm: '',
      disableConfirm: false
    };
  },
  mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.sPepPactientPasswordReset = new ServicoAll(
      '/api/pep/paciente/password-reset',
      this.servicoHttp
    );
  },
  methods: {
    handleSubmit() {
      const isPasswordValid = this.$refs.PasswordCheckIndicator.isPasswordValid();
      if (!isPasswordValid) {
        return this.$toast.error('A senha não cumpre os requisitos obrigatórios.', { duration: 3000 });
      }
      const isPasswordEqual = this.passwordConfirm === this.newPassword;
      if (!isPasswordEqual) {
        return this.$toast.error('A senhas não são iguais.', { duration: 3000 });
      }
      this.resetPassword();
    },
    async resetPassword() {
      try {
        this.disableConfirm = true;
        const hash = this.getHashUrl();
        const input = {
          hash,
          password: this.newPassword
        };
        await this.sPepPactientPasswordReset.post(input);
        this.$toast.success('Senha atualizada com sucesso! Redirecionando para login...');
        setTimeout(async () => {
          await this.$router.push({ name: 'pacienteLogin' });
        }, 1000);
      } catch (error) {
        if (error.response && error.response.data) {
          this.$toast.error(error.response.data.message, { duration: 3000 });
        }
      } finally {
        this.disableConfirm = false;
      }

    },
    getHashUrl() {
      const params = this.$route.params;
      return params && params.hash ? params.hash : null;
    },
    async goBack() {
      await this.$router.push({ name: 'pacienteLogin' });
    }
  }
};
</script>

