<style src="./ForgotPsw.scss" lang="scss" scoped></style>
<template>
  <section class="home color-overlay">
    <section class="back-form-box">
      <section class="form-box">
        <img class="logo" :src="appLogo" />
        <div class="title">Esqueci a Senha</div>
        <section class="cpf">
          <div class="cpf">
            <InputText isRequired label="CPF"
                       mask="###.###.###-##" v-model="formNext.cpf"
            />
          </div>
          <div class="cpf">
            <InputText isRequired label="E-mail" v-model="formNext.email" />
          </div>
        </section>
        <div class="act">
          <div class="msg">{{ msgError }}</div>
          <div class="btn" @click="onActNext">Confirmar</div>
          <div class="back" @click="onActBack">Voltar</div>
        </div>
      </section>
    </section>
    <!-- *** -->
  </section>
</template>

<script>
import { ServicoHttp } from '@/axios/servico-http';
import { ServicoAll } from '@/axios/servico-all';

import InputText from '@/components/input/text/InputText';

export default {
  name: 'PortalPatientLogin',
  components: {
    InputText
  },

  data() {
    return {
      formNext: {
        cpf: null,
        email: null
      },
      msgError: ''
    };
  },

  computed: {
    appLogo() {
      return process.env.VUE_APP_IMG_LOGO;
    }
  },

  mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.sPepPatientRequestPasswordChange = new ServicoAll('/api/pep/paciente/request-password-change', this.servicoHttp);
  },

  methods: {
    async onActNext() {
      try {
        if (!this.formNext.cpf) {
          this.msgError = 'CPF não informado';
          console.warn('CPF não informado');
          return;
        }
        if (!this.formNext.email) {
          this.msgError = 'E-MAIL não informado';
          return;
        }
        const ret = await this.sPepPatientRequestPasswordChange.post({
          ...this.formNext
        });
        const data = ret.data;
        this.$toast.success(data.message, { duration: 3000 });
        setTimeout(async () => {
          await this.$router.push({ name: 'pacienteLogin' });
        }, 2000);
      } catch (error) {
        if (error && error.response) {
          this.$toast.error(error.response.data.message, { duration: 3000 });
        }
      }
    },

    onActBack() {
      this.$router.push({ name: 'pacienteLogin' });
    }
  }
};
</script>
