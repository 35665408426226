import moment from 'moment'

import store from '../store'

import Firebase from "@/helpers/firebase/index";
import { ServicoHttp } from "../axios/servico-http";

const encludeRoutesToAuthMiddleware = [
  'pacienteLogin',
  'pacienteVideo',
  'forgotPsw',
  'passwordReset',
  'patient-terms'
]

const servicoHttp = new ServicoHttp({});

const beforeEach = async (to, _from, next) => {
  if (to.path.includes('/cabine')) {
    return next()
  }

  const storagePatient = await store.dispatch("getLocalStoragePatient");

  if (to.path.includes('/portal-paciente/solicitar-pronto-atendimento') && to.query?.cpf) {
    const cpfSha1 = await cryptoSha1(to.query.cpf)
    const { data: response } = await servicoHttp.get({
      url: "/api/pep/paciente/login-by-hash",
      params: { hash: cpfSha1 },
    })

    const { status, data: user } = response
    if (!status) {
      return next({ name: 'pacienteLogin', query: { message: 'Falha ao obter paciente, tente novamente mais tarde!' } });
    }

    await store.dispatch("setLocalStoragePatient", user);
    await Firebase.setFirestoreConfig();
    await Firebase.addPatient({
      pacId: user.pac_id,
      pacNome: user.pac_nome,
      pacImagem: user.usu_imagem_url,
      usuCpf: user.usu_cpf || 'Não Informado',
      pacGenero: user.usu_genero === "m" ? "Masculino" : "Feminino",
      pacIdade: moment().diff(moment(user.usu_nascimento, "YYYY-MM-DD"), "years"),
    });

    return next()
  }

  if (to.path.includes('/atendimento-mobile') && to.query?.authHash) {
    const { data: response } = await servicoHttp.get({
      url: "/api/pep/paciente/login-by-hash",
      params: { hash: to.query.authHash },
    })

    const { status, data: user } = response
    if (!status) {
      return next({ name: 'pacienteLogin', query: { message: 'Falha ao obter paciente, tente novamente mais tarde!' } });
    }

    await Firebase.setFirestoreConfig();
    await store.dispatch("setLocalStoragePatient", user);

    await Firebase.addPatient({
      pacId: user.pac_id,
      pacNome: user.pac_nome,
      pacImagem: user.usu_imagem_url,
      usuCpf: user.usu_cpf || 'Não Informado',
      pacGenero: user.usu_genero === "m" ? "Masculino" : "Feminino",
      pacIdade: moment().diff(moment(user.usu_nascimento, "YYYY-MM-DD"), "years"),
    });

    return next()
  }

  if (to.path.includes('/cabine')) {
    return next()
  }

  if (!storagePatient && !encludeRoutesToAuthMiddleware.includes(to.name)) {
    return next({ name: 'pacienteLogin', query: { message: 'Atenção, você precisa estar logado para acessar este endereço!' } });
  }

  if (!storagePatient && to.name === 'pacienteVideo') {
    if (!to.query?.key) {
      return next({ name: 'pacienteLogin', query: { message: 'Atenção, você precisa estar logado para acessar este endereço!' } });
    }

    const { data: response } = await servicoHttp.get({
      url: "/api/pep/paciente/login-video-url",
      params: { hash: to.query.key },
    })

    if (!response.status) {
      return next({ name: 'pacienteLogin', query: { message: 'Falha ao obter paciente, tente novamente mais tarde!' } });
    }

    await store.dispatch("setLocalStoragePatient", response.data);
    return next()
  }

  if (storagePatient && to.name === 'pacienteVideo' && to.query?.key) {
    await store.dispatch('resetPatientToVideoUrl')
    const { data: response } = await servicoHttp.get({
      url: "/api/pep/paciente/login-video-url",
      params: { hash: to.query.key },
    })
    // console.log('[response]', response)

    if (!response.status) {
      return next();
    }

    if (storagePatient.usu_cpf !== response.data.usu_cpf) {
      await store.dispatch("setPatientToVideoUrl", response.data);
    }

    return next()
  }

  return next()
}


async function cryptoSha1(message) {
  return Array.from(
    new Uint8Array(
      await crypto.subtle.digest('SHA-1', new TextEncoder().encode(message))
    ),
    (byte) => byte.toString(16).padStart(2, '0')
  ).join('');
}

export default beforeEach

