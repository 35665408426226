<style scoped lang="scss">
.password-indicator {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media(max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
  gap: 0.3rem;
  margin-top: 0.5rem;

  .password-indicator__item {
    display: flex;
    align-items: center;
    margin: 0.25rem 0;

    .password-indicator__item__ball {
      margin-right: 0.3rem;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.8rem;
      border: 4px solid #A9A9A9;
      transition: all 0.5s ease;

      &.on {
        border: 4px solid #0BB98C;
        background-color: #0BB98C;
      }
    }

    .password-indicator__item__label {
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 19.36px;

    }
  }

}
</style>
<template>
  <section class="password-indicator">
    <div class="password-indicator__item" v-for="(item,index) in passwordRequirements" :key="index">
      <div class="password-indicator__item__ball" :class="{ on: passwordValidations[item.field] }" />
      <span class="password-indicator__item__label" :title="item.label">{{ item.label }}</span>
    </div>
  </section>
</template>

<script>
const passwordRequirements = [
  {
    label: '8 caracteres.',
    field: 'characters'
  },
  {
    label: '1 letra minúscula.',
    field: 'min'

  },
  {
    label: '1 caracter especial.',
    field: 'special'
  },
  {
    label: '1 número.',
    field: 'num'
  },
  {
    label: '1 letra maiúscula.',
    field: 'max'
  }
];
export default {
  name: 'PasswordCheckIndicator',
  props: {
    newPassword: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      passwordRequirements: passwordRequirements,
      passwordValidations: {
        characters: false,
        min: false,
        special: false,
        num: false,
        max: false
      }
    };
  },
  watch: {
    newPassword: {
      handler(input) {
        this.validatePassword(input);
      },
      immediate: true
    }
  },
  methods: {
    validatePassword(password) {
      if (!password) {
        this.passwordValidations = {
          caracter: false,
          min: false,
          special: false,
          num: false,
          max: false
        };
        return;
      }
      this.passwordValidations = {
        characters: password.length >= 8,
        min: /[a-z]/.test(password),
        special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        num: /\d/.test(password),
        max: /[A-Z]/.test(password)
      };
    },
    isPasswordValid() {
      this.validatePassword(this.newPassword);
      return Object.values(this.passwordValidations).every((validation) => validation === true);
    }
  }
};
</script>
