import { render, staticRenderFns } from "./PatientVideo.vue?vue&type=template&id=1479412e&scoped=true"
import script from "./PatientVideo.vue?vue&type=script&lang=js"
export * from "./PatientVideo.vue?vue&type=script&lang=js"
import style0 from "./PatientVideo.scss?vue&type=style&index=0&id=1479412e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1479412e",
  null
  
)

export default component.exports